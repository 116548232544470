import React from "react";
import { Field } from "formik-next";

import {
    CAFE_HALLONBERGEN_COMPANY_ID,
    RÅ_OCH_SÅ_AB_COMPANY_ID,
    SALLADSBAREN_I_GAMLESTADEN_COMPANY_ID
} from "Constants";
import { SelectInput } from "Molecules";
import { useLanguage } from "LanguageProvider";
import { useQoplaStore } from "Stores";
import { CustomerPickupFormComponent } from "../..";

export const CateringContentTimeIntervalField: React.FC<CustomerPickupFormComponent> = props => {
    const { translate } = useLanguage();
    const { selectedShop } = useQoplaStore();

    const isCafeHallonBergen = CAFE_HALLONBERGEN_COMPANY_ID === selectedShop?.companyId;
    const isRaOchSa = RÅ_OCH_SÅ_AB_COMPANY_ID === selectedShop?.companyId;
    const isSalladBaren = SALLADSBAREN_I_GAMLESTADEN_COMPANY_ID === selectedShop?.companyId;

    const willDeliverCatering = props.values.willDeliverCatering === "YES";

    if ((!isCafeHallonBergen && !isRaOchSa && !isSalladBaren) || !willDeliverCatering) {
        return null;
    }

    const optionsMap: Record<string, any[]> = {
        [SALLADSBAREN_I_GAMLESTADEN_COMPANY_ID]: [{ value: "10:00-13:00", label: "10:00-13:00" }],
        [RÅ_OCH_SÅ_AB_COMPANY_ID]: [{ value: "18:00-22:00", label: "18:00-22:00" }],
        [CAFE_HALLONBERGEN_COMPANY_ID]: [
            { value: "08:00-13:00", label: "08:00-13:00" },
            { value: "13:00-18:00", label: "13:00-18:00" },
            { value: "18:00-22:00", label: "18:00-22:00" }
        ]
    };

    const options = optionsMap[selectedShop?.companyId] || [];

    return (
        <Field
            as={SelectInput}
            formLabel={translate("timeInterval")}
            placeholder={translate("timeInterval")}
            name="timeInterval"
            setFieldValue={props.setFieldValue}
            onBlur={props.setFieldTouched}
            options={options}
            isMandatory
            validate={(value: string) => {
                if (!value) return translate("requiredError");
            }}
        />
    );
};
